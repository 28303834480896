import React from "react";
import { navigate } from "gatsby";
import { Box, Heading, Grid } from "grommet";
import { CarouselBlock } from "./CarouselBlock";
import { useResponsive } from "../../hooks";

export const CaseStudy = ({
  caseStudy: { thumbnailImages, title, keyInformation, uri },
}) => {
  const { isMobile } = useResponsive();
  return (
    <Box basis="1/2" flex="grow" margin={{ vertical: "medium" }}>
      <Box
        height="0"
        pad={{ bottom: isMobile ? "125%" : "56%" }}
        style={{ position: "relative" }}
        background="black"
        flex={false}>
        {thumbnailImages?.images && (
          <CarouselBlock images={thumbnailImages?.images} uri={uri}/>
        )}
      </Box>

      <Box fill="horizontal" direction="column" onClick={() => navigate(uri)}>
        <Box
          direction="row"
          justify="between"
          margin={{ top: "xsmall", bottom: "none" }}
          border={{ side: "bottom", size: "small", color: "black" }}>
          <Box flex="grow" pad="none" margin="none" alignSelf="start">
            <Heading
              level={4}
              size="small"
              // truncate={isMobile}
              margin={{ vertical: "xsmall" }}>
              {title}
            </Heading>
          </Box>
        </Box>
        <Box direction="row" justify="between" flex="shrink">
          <Heading level={4} size="small" margin={{ vertical: "xsmall" }}>
            {keyInformation.sector}
          </Heading>
          <Heading level={4} size="small" margin={{ vertical: "xsmall" }}>
            {keyInformation.cost}
          </Heading>
        </Box>
      </Box>
    </Box>
  );
};

export const CaseStudies = ({ caseStudies }) => {
  const { isMobile, isDesktop, themeSizes } = useResponsive();

  return (
    <Box
      pad={{ vertical: "xlarge" }}
      flex="grow"
      basis="full"
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}>
      <Grid
        fill="horizontal"
        columns={{ count: isMobile ? 1 : 2, size: "auto" }}
        gap="medium">
        {caseStudies?.map((caseStudy, index) => (
          <CaseStudy key={index} caseStudy={caseStudy} />
        ))}
      </Grid>
    </Box>
  );
};
