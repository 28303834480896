import { graphql } from "gatsby";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Box } from "grommet";
import React from "react";
import "video.js/dist/video-js.css";
import Layout from "../components/layout";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CaseStudies } from "./PageBlocks/CaseStudies";
import { ClientLogos } from "./PageBlocks/ClientLogos";
import { OtherProjects } from "./PageBlocks/OtherProjects";
import { TitleBlock } from "./PageBlocks/TitleBlock";
import { VideoBlock } from "./PageBlocks/VideoBlock";
import Divider from "../components/Divider";
import DivisionShowcase from "../components/DivisionShowcase";
import FacilitiesAboutBlock from "../components/FacilitiesAboutBlock";
import { MaintenanceOverview } from "./FacilitiesBlocks/maintenanceOverview";
import { MaintenanceServices } from "./FacilitiesBlocks/maintenanceServices";
import { ReactiveOverview } from "./FacilitiesBlocks/reactiveOverview";
import { ReactiveServices } from "./FacilitiesBlocks/reactiveServices";

const FacilitiesContent = ({
  maintenanceOverview,
  maintenanceServices,
  reactiveOverview,
  reactiveServices,
  logos,
}) => {
  return (
    <>
      <ReactiveOverview content={reactiveOverview} />
      <Divider />
      <ReactiveServices content={reactiveServices} />
      <Divider />
      <MaintenanceOverview content={maintenanceOverview} />
      <Divider />
      <MaintenanceServices content={maintenanceServices} />
      <Divider />
      <FacilitiesAboutBlock />
      <Divider />
      <DivisionShowcase showcaseType={"other"} hiddenDivision={"facilities"} />
      <ClientLogos logos={logos} />
    </>
  );
};

const PageTemplate = ({ data: { page } }) => {
  const {
    imageOrVideo,
    title,
    content,
    categoryPageSections: { caseStudies, logos, otherProjects, brandSpirit },
    maintenanceResponseOverview,
    maintenanceResponseServices,
    reactiveResponseOverview,
    reactiveResponseServices,
  } = page;

  if (title === "Facilities") {
    return (
      <Layout isHomePage>
        <Seo post={page} />
        <TitleBlock title={title} content={content} brandSpirit={brandSpirit} />
        <VideoBlock data={imageOrVideo} />
        <FacilitiesContent
          maintenanceOverview={maintenanceResponseOverview}
          maintenanceServices={maintenanceResponseServices}
          reactiveOverview={reactiveResponseOverview}
          reactiveServices={reactiveResponseServices}
          logos={logos}
        />
      </Layout>
    );
  } else {
    return (
      <Layout isHomePage>
        <Seo post={page} />
        <Box
          width={{ min: "100%" }}
          basis="full"
          flex="grow"
          pad={{ bottom: "50px" }}
          align="center"
        >
          <TitleBlock
            title={title}
            content={content}
            brandSpirit={brandSpirit}
          />
          <VideoBlock data={imageOrVideo} />
          <CaseStudies caseStudies={caseStudies} />
          <OtherProjects otherProjects={otherProjects} title={title} />
          <DivisionShowcase showcaseType={"other"} hiddenDivision={title} />
          <ClientLogos logos={logos} />
        </Box>
      </Layout>
    );
  }
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        metaRobotsNoindex
        metaRobotsNofollow
      }
      imageOrVideo {
        video
        type
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000, quality: 50, layout: CONSTRAINED)
            }
          }
        }
      }
      categoryPageSections {
        brandSpirit
        otherProjects {
          ... on WpCaseStudy {
            id
            title
            uri
            excerpt
            content
            blocks {
              blockName: name
              innerHTML: saveContent
              dynamicContent
              innerBlocks {
                blockName: name
                innerHTML: saveContent
                innerBlocks {
                  blockName: name
                  innerHTML: saveContent
                }
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 750
                      quality: 50
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
            keyInformation {
              location
              year
              sector
              scope
              fieldGroupName
              duration
              cost
            }
          }
        }
        caseStudies {
          ... on WpCaseStudy {
            id
            title
            uri
            thumbnailImages {
              images {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1000
                      quality: 50
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                title
              }
            }
            keyInformation {
              location
              year
              sector
              scope
              fieldGroupName
              duration
              cost
            }
          }
        }
        logos {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 50, layout: CONSTRAINED)
            }
          }
        }
      }
      ... on WpPage {
        ...FacilitiesContent
      }
    }
  }
  fragment FacilitiesContent on WpPage {
    maintenanceResponseOverview {
      maintenanceTitle
      maintenanceBody
      maintenanceResponseLevels {
        level
        text
      }
      maintenanceImages {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1000, quality: 50, layout: CONSTRAINED)
          }
        }
      }
    }
    maintenanceResponseServices {
      maintenanceServices {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 50, layout: CONSTRAINED)
            }
          }
        }
        subCategories {
          subCategoryText
          subCategoryImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  quality: 50
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    reactiveResponseOverview {
      title
      body
      responseLevels {
        level
        text
      }
      images {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1000, quality: 50, layout: CONSTRAINED)
          }
        }
      }
    }
    reactiveResponseServices {
      responseServices {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 50, layout: CONSTRAINED)
            }
          }
        }
        subCategories {
          subCategoryText
          subCategoryImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  quality: 50
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`;
