import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import CustomEase from 'gsap/CustomEase';

const AnimatedTitle = ({ as: HeaderTag = 'h1', children, className }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase);

    const title = titleRef.current;
    if (!title) return;

    let split = new SplitText(title, {
      type: "lines",
      linesClass: "lineParent"
    });

    split.lines?.forEach(line => {
      new SplitText(line, {
        type: "lines",
        linesClass: "lineChild"
      });
    });

    const lineChildren = title.querySelectorAll(".lineChild");
    gsap.set(lineChildren, { yPercent: 100 });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: title,
        start: "bottom bottom",
        end: "top bottom",
        once: true
      }
    });

    tl.to(lineChildren, {
      duration: 1.25,
      yPercent: 0,
      stagger: 0,
      ease: CustomEase.create("custom", "M0,0 C0.083,0.294 0.182,0.677 0.448,0.867 0.579,0.96 0.752,1 1,1 "),
    });

    return () => {
      tl?.kill();
      split?.revert();
    };
  }, []);

  return (
    <HeaderTag ref={titleRef} className={className}>
      {children}
    </HeaderTag>
  );
};

export default AnimatedTitle;