import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from './Button'
import Marquee from "react-fast-marquee";
import AnimatedTitle from "./AnimatedTitle";

const CardBadge = ({ level }) => {
  return (
    <div className={"flex items-center justify-center size-10 text-secondary font-medium border-2 border-secondary rounded-full aspect-square flex-shrink-0 tablet:size-12"}>
      <p className={"text-sm tablet:text-base leading-2"}>{level}</p>
    </div>
  )
}

const OverviewGallery = ({ title, description, images, cards }) => {
  return (
    <div className={"min-h-screen bg-light"}>
      {/*Container*/}
      <div className={"content-container py-14"}>
        {/*Header*/}
        <div className={"grid grid-cols-1 tablet:grid-cols-2"}>
          {/*Content*/}
          <div className={"tablet:order-1"}>
            <AnimatedTitle as={'h2'} className={"font-bold text-xl leading-[1.2] mb-6 text-secondary tablet:mb-10 tablet:text-3xl desktop:mb-8 desktop:mt-4"}>
              {title}
            </AnimatedTitle>
            <p className={"font-medium text-secondary text-sm tablet:text-base"}>{description}</p>
          </div>

          {/*Cards*/}
          <div className={"tablet:-order-1"}>
            {/*Card list*/}
            <ul className={"grid grid-cols-2 mt-4 tablet:mt-0 tablet:grid-cols-1 gap-1 tablet:gap-2"}>
              {cards?.map((card, index) => {
                return (
                  <li key={index} className={"flex items-center gap-2 tablet:gap-4"}>
                    <CardBadge level={card.level} />
                    <p className={"text-secondary font-medium text-sm tablet:text-base"}>{card.text}</p>
                  </li>
                )
              })}
            </ul>

            {/*Cta*/}
            <div className={"mt-5"}>
              <Button text={"Get In Touch"} color={"secondary"} to={"mailto:fm@bauvill.co.uk"} isExternal={true} />
            </div>
          </div>
        </div>

        {/*Gallery*/}
        <div className={"flex items-center overflow-hidden mt-12 h-[35vh] tablet:h-[60vh] desktop:mt-20"}>
          {/*Gallery list*/}
          <div className={"absolute left-0 right-0 h-[35vh] tablet:h-[60vh] overflow-hidden"}>
            <Marquee speed={100}>
              <ul
                className={"flex h-[35vh] w-full whitespace-nowrap tablet:h-[60vh]"}>
                {images?.map((image, index) => {
                  const gatsbyImage = getImage(image.localFile);
                  return (
                    <li className={"w-[65vw] flex-shrink-0 px-2 tablet:w-[500px]"} key={index}>
                      {gatsbyImage && (
                        <GatsbyImage
                          image={gatsbyImage}
                          alt={image.altText || ""}
                          className={"w-full " + (index % 2 === 0 ? "h-full" : "h-2/3")}
                          imgClassName={"object-cover"}
                        />
                      )}
                    </li>
                  )
                })}
              </ul>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewGallery