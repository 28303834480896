import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import AnimatedTitle from "./AnimatedTitle";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CrossFadeImage from "./CrossFadeImage";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const SubCategory = ({ text, index, setActiveSubCategory, activeSubCategory, isLastItem, categoryIndex, isFirstItemInCategory = false }) => {
  const containerRef = useRef(null);
  const mmRef = useRef(null);
  const [active, setActive] = useState(false);
  const activeSubCategoryRef = useRef(null);

  useEffect(() => {
    activeSubCategoryRef.current = activeSubCategory;
  }, [activeSubCategory]);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    mmRef.current = gsap.matchMedia();

    gsap.set(containerRef.current, {
      x: 0,
      opacity: 0.5
    });

    const tl = gsap.timeline({
      paused: true,
      reversed: true,
    });

    mmRef.current.add("(max-width: 575px)", () => {
      tl.fromTo(containerRef.current, {
        opacity: 0.5,
      }, {
        opacity: 1,
        ease: "power2.inOut",
        duration: 0.5
      });
    });

    mmRef.current.add("(min-width: 576px) and (max-width: 1279px)", () => {
      tl.fromTo(containerRef.current, {
        opacity: 0.5,
        x: 0,
      }, {
        x: 25,
        opacity: 1,
        ease: "power2.inOut",
        duration: 0.5,
        onComplete: () => {
          nudgeScroll()
        }
      });
    });

    mmRef.current.add("(max-width: 1279px)", () => {
      ScrollTrigger.create({
        trigger: containerRef.current,
        start: "top center",
        end: "bottom center",
        onEnter: () => {
          tl.play();
          setActiveSubCategory(index);
        },
        onLeave: () => {
          if (!isLastItem) {
            tl.reverse();
          }
        },
        onEnterBack: () => {
          setActiveSubCategory(index);

          if (!isLastItem) {
            tl.play();
          }
        },
        onLeaveBack: () => {
          tl.reverse();
        },
        markers: false,
      });
    });

    return () => {
      mmRef.current.revert();
    };
  }, [isLastItem, index, setActiveSubCategory]);

  const handleScroll = () => {
    if (!active && activeSubCategoryRef.current !== index) return;

    clearTimeout(window.scrollTimeout);
    setScrollTimeout();
  };

  function setScrollTimeout() {
    window.scrollTimeout = setTimeout(() => {
      nudgeScroll()
    }, 500);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(window.scrollTimeout);
    };
  }, []);

  function nudgeScroll() {
    if (!mmRef.current) return;

    mmRef.current.add("(min-width: 576px) and (max-width: 1279px)", () => {
      const item = containerRef.current;

      if (!item || (!active && activeSubCategoryRef.current !== index) || isLastItem) return;

      const itemRect = item.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const itemMiddle = itemRect.top + itemRect.height / 2;
      const screenMiddle = windowHeight / 2;
      const difference = itemMiddle - screenMiddle;

      // Make sure the middle of the window is within the bounds of the item
      if (difference > (itemRect.height / 2) || difference > (itemRect.height / 2)){
        console.log("difference too large", difference, itemRect.height)
        return;
      }

      // Check if the difference is significant enough to scroll
      if (Math.abs(difference) > 0) { // Threshold for triggering animation
        const currentScrollY = window.scrollY;
        const targetScrollY = currentScrollY + difference;

        // Ensure the target scroll position is within the document bounds
        const maxScrollY = document.documentElement.scrollHeight - windowHeight;
        const finalScrollY = Math.min(Math.max(targetScrollY, 0), maxScrollY);

        // Only scroll if the target position is different from the current position
        if (Math.abs(finalScrollY - currentScrollY) > 1) {
          gsap.to(window, {
            duration: 0.5,
            scrollTo: finalScrollY,
            ease: "power2.out",
          });
        }
      }
    });
  }

  const onMouseEnter = () => {
    gsap.fromTo(containerRef.current, {
      opacity: 0.5
    }, {
      opacity: 1,
      ease: "power2.inOut",
      duration: 0.25
    });
    setActiveSubCategory(index);
    setActive(true);
  }

  const onMouseLeave = () => {
    if (active) return;

    gsap.fromTo(containerRef.current, {
      opacity: 1
    }, {
      opacity: 0.5,
      ease: "power2.inOut",
      duration: 0.25
    });
  }

  useEffect(() => {
    if (activeSubCategory === index) {
      setActive(true);
    } else if (active) {
      setActive(false);
      gsap.fromTo(containerRef.current, {
        opacity: 1
      }, {
        opacity: 0.5,
        ease: "power2.inOut",
        duration: 0.25
      });
    }
  }, [activeSubCategory]);

  return (
    <p
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`flex text-center justify-center items-center text-secondary text-2xl font-bold leading-[1] py-3 tablet:justify-start tablet:cursor-pointer tablet:text-left desktop:py-0 desktop:h-[48px] desktop:whitespace-nowrap ${isFirstItemInCategory && `category-scroller-first-category-item-${categoryIndex}`}`}
      key={`${text.replaceAll(" ", "").toLowerCase()}`}
    >
      {text}
    </p>
  )
}

const CategoryScroller = ({title, categories}) => {
  const [activeCategory, setActiveCategory] = useState(null)
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)
  const detailsRef = useRef(null);
  const subCategoryRef = useRef(null);
  let subCategories = []

  useEffect(() => {
    setActiveCategory(subCategories[activeSubCategory]?.categoryText)
    setCurrentImage(subCategories[activeSubCategory]?.image)
  }, [activeSubCategory]);

  const setActiveCategoryImageFirstSubCategory = (categoryIndex) => {
    setCurrentImage(categories[categoryIndex]?.subCategories[0]?.subCategoryImage)
  }

  useLayoutEffect(() => {
    if (!detailsRef.current) return;

    const trigger = ScrollTrigger.create({
      trigger: detailsRef.current,
      start: "top top",
      endTrigger: subCategoryRef.current,
      end: "bottom bottom+=20px",
      pin: true,
      pinSpacing: false,
    });

    return () => {
      trigger.kill();
    };
  }, []);

  if (!categories) return null;

  // Loop through the categories, and get list of sub-categories
  categories?.map((category) => {
    // Add entries from sub category array into subCategories
    category?.subCategories?.map((subCategory) => {
      subCategories.push({
        text: subCategory.subCategoryText,
        categoryText: category.text,
        image: subCategory.subCategoryImage,
      })
    })
  })

  const activeImage = getImage(currentImage?.localFile) || getImage(subCategories[0]?.image?.localFile);

  return (
    <div className={"min-h-screen w-full bg-light"}>
      {/*Container*/}
      <div className={"content-container relative flex flex-col tablet:grid tablet:grid-cols-2 gap-3"}>
        {/*Details*/}
        <div ref={detailsRef} className={"w-full h-full flex flex-col justify-between bg-light relative z-10 tablet:static table:py-10 tablet:h-screen tablet:bg-transparent"}>
          {/*Background mask*/}
          <div className={"bg-light absolute top-0 bottom-0 -translate-x-1/2 left-1/2 w-screen h-full tablet:hidden"}>
          </div>

          {/*Title*/}
          <AnimatedTitle as={'h2'} className={"font-bold text-xl leading-[1] text-secondary pt-5 pb-12 w-2/3 tablet:w-auto tablet:text-2xl tablet:leading-[1.2] tablet:pt-3 tablet:text-4xl tablet:w-3/4 desktop:w-[450px]"}>
            {title}
          </AnimatedTitle>

          {/*Category*/}
          <p className="block relative bg-light z-10 right-0 mx-auto text-secondary border-secondary border-2 rounded-full font-bold px-10 py-2 text-xl min-w-[150px] max-w-[230px] text-center tablet:absolute tablet:top-1/2 tablet:-translate-y-1/2 desktop:hidden">
            {activeCategory ?? subCategories[0]?.categoryText}
          </p>

          {/*Image*/}
          <div className={"hidden relative no-gatsby-image-transition tablet:block tablet:h-[30vh] tablet:w-3/4 desktop:h-[calc(50%+20px)] desktop:w-[60%]"}>
            {activeImage && (
              <CrossFadeImage
                image={activeImage}
                alt={currentImage?.alt ?? subCategories[0]?.image?.alt ?? ""}
              />
            )}
          </div>
        </div>

        {/*Sub-category list*/}
        <div ref={subCategoryRef} className={"relative"}>

          {/*Sticky category*/}
          <div className={"hidden desktop:block"}>
            {Categorieslist(categories)?.map((category, index) => {
              return (
                <StickyCategory key={index} text={category.categoryText} categoryIndex={index} subCategoryContainerRef={subCategoryRef} setActiveCategoryImageFirstSubCategory={setActiveCategoryImageFirstSubCategory} />
              )
            })}
          </div>

          {/*Spacer*/}
          <div className={"hidden tablet:block h-[calc(50vh-30px)] w-1"} />

          <div className={"grid grid-cols-1 sub-categories-list"}>
            {Categorieslist(categories)?.map((category, index) => {
              const categoryIndex = index;
              return category.subCategories.map((subCategory, index) => {
                  return (
                    <SubCategory
                      key={subCategory.index}
                      categoryIndex={categoryIndex}
                      index={subCategory.index}
                      text={subCategory.text}
                      setActiveSubCategory={setActiveSubCategory}
                      activeSubCategory={activeSubCategory}
                      isFirstItemInCategory={index === 0}
                      isLastItem={subCategory.index === category.subCategories.length - 1}
                    />
                  )
                }
              )
            })}
          </div>

          {/*Spacer*/}
          <div className={"hidden tablet:block h-[50vh] w-1"}>
          </div>
        </div>
      </div>
    </div>
  )
}

const StickyCategory = ({ text, subCategoryContainerRef, categoryIndex, setActiveCategoryImageFirstSubCategory }) => {
  const stickyCategoryRef = useRef(null);
  const [position, setPosition] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const previousPosition = useRef(0);

  const updatePosition = () => {
    const firstCategoryItem = subCategoryContainerRef?.current?.querySelector(`.category-scroller-first-category-item-${categoryIndex}`);

    if (firstCategoryItem && stickyCategoryRef.current) {
      const firstCategoryItemRect = firstCategoryItem.getBoundingClientRect();
      const screenMiddle = window.innerHeight / 2;

      // Calculate the position to follow the first item exactly
      const followPosition = firstCategoryItemRect.top + firstCategoryItemRect.height / 2;

      // Ensure the position doesn't go above the middle of the screen
      let newPosition = Math.max(followPosition, screenMiddle);

      // Get sub category div
      const subCategoryDiv = subCategoryContainerRef?.current?.querySelector(".sub-categories-list");

      // Make sure position doesn't go below the bottom of the sub category div
      const maxPosition = subCategoryDiv.getBoundingClientRect().bottom - stickyCategoryRef.current.getBoundingClientRect().height / 2;

      // Set the position to the max position if it's greater than the max position
      if (newPosition > maxPosition) {
        newPosition = maxPosition;
        setOpacity(1)
      } else {
        setOpacity(categoryIndex === 0 ? 1 : newPosition === screenMiddle ? 1 : 0.5);
      }

      if (newPosition !== previousPosition.current){
        if (newPosition === screenMiddle){
          // Set the active category
          setActiveCategoryImageFirstSubCategory(categoryIndex);
        } else if (previousPosition.current === screenMiddle){
          setActiveCategoryImageFirstSubCategory(Math.max(categoryIndex - 1, 0));
        }
      }

      previousPosition.current = newPosition;
      setPosition(newPosition);
    }
  };

  const pageLoad = () => {
    setOpacity(0.5)
  }

  useEffect(() => {
    updatePosition();

    const handleScrollAndResize = () => {
      requestAnimationFrame(updatePosition);
    };

    window.addEventListener('scroll', handleScrollAndResize);
    window.addEventListener('resize', handleScrollAndResize);
    window.addEventListener('load', pageLoad);

    return () => {
      window.removeEventListener('scroll', handleScrollAndResize);
      window.removeEventListener('resize', handleScrollAndResize);
      window.removeEventListener('load', pageLoad);
    };
  }, [categoryIndex]);

  return (
    <p
      ref={stickyCategoryRef}
      className="block fixed left-1/2 bg-light z-10 text-secondary border-secondary border-2 rounded-full font-bold px-10 py-2 text-xl text-center desktop:w-[250px] desktop:h-[48px] desktop:truncate"
      style={{
        top: `${position}px`,
        transform: `translate(calc(-100% - 0.25rem), -50%)`,
        opacity: opacity,
        zIndex: opacity === 1 ? 100 : 10
      }}
    >
      {text}
    </p>
  );
};


const Categorieslist = (categories) => {
  let subCategoryIndex = 0;
  const groupedList = categories?.reduce((acc, category) => {
    const subCategories = category?.subCategories?.map((subCategory) => ({
      index: subCategoryIndex++,
      text: subCategory.subCategoryText,
      categoryText: category.text,
      image: subCategory.subCategoryImage,
    })) ?? [];

    if (subCategories.length > 0) {
      acc.push({
        categoryText: category.text,
        subCategories: subCategories
      });
    }

    return acc;
  }, []) ?? [];

  return groupedList;
};

export default CategoryScroller;