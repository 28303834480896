import Image from "gatsby-image";
import {
  Accordion,
  AccordionPanel,
  Box,
  Heading,
  Grid,
  Layer,
  Button,
  Paragraph,
} from "grommet";
import parse from "html-react-parser";
import React, { useState } from "react";
import Plus from "../../assets/svgs/plus.svg";
import Minus from "../../assets/svgs/minus.svg";
import { useResponsive } from "../../hooks";
import CaseStudyPopUp from "../Blocks/CaseStudyPopup";
import { Close } from "grommet-icons";
import ArrowMore from "../../assets/svgs/arrow-more.svg";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const OtherProjectItem = ({
  projectDetails: {
    featuredImage: featured,
    title,
    content,
    excerpt,
    keyInformation,
    uri,
  },
  projectDetails,
  active,
}) => {
  const { isMobile, isDesktop, isTablet, themeSizes } = useResponsive();

  const featuredImage = {
    fluid: getImage(featured?.node?.localFile),
    alt: featured?.node?.altText || ``,
  };

  const renderHeader = (title, sector) => {
    return (
      <Box
        border={{ side: "bottom", size: "small", color: "brand" }}
        direction="row"
        justify="between"
        align="center"
      >
        <Grid
          gap={"small"}
          columns={isMobile ? ["flex", "50px"] : ["flex", "25%", "200px", "50px"]}
          fill="horizontal"
        >
          <Heading level={4} size="small" margin={{ vertical: "small" }}>
            {title}
          </Heading>
          {isDesktop && (
            <>
              <Heading level={4} size="small" margin={{ vertical: "small" }}>
                {keyInformation.scope}
              </Heading>
              <Box flex="grow" align="end">
                <Heading
                  level={4}
                  size="small"
                  margin={{ vertical: "small" }}
                  // textAlign="right"
                >
                  {keyInformation.cost}
                </Heading>
              </Box>
            </>
          )}
          <Box
            flex
            justify="center"
            align="end"
            margin={{ vertical: "xsmall" }}
          >
            {!active ? (
              <Plus height="30px" width="30px"></Plus>
            ) : (
              <Minus height="30px" width="30px"></Minus>
            )}
          </Box>
        </Grid>
      </Box>
    );
  };


  return (
    <>
      <AccordionPanel
        header={() => renderHeader(title)}
        style={{ border: "0px transparent solid", background: "transparent" }}
      >
        <Box
          pad={{ vertical: "medium", horizontal: isMobile ? "none" : "xlarge" }}
          direction={isMobile ? "column" : "row"}
          gap="medium"
        >
          <Box
            basis="1/2"
            style={{
              height: 0,
              position: "relative",
              paddingBottom: isMobile ? "50%" : "25%",
            }}
          >
            <GatsbyImage
              alt={featuredImage.alt}
              image={featuredImage.fluid}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Box basis="1/2" align="start">
            {parse(excerpt)}
            <PopUpCaseStudy data={projectDetails} />
          </Box>
        </Box>
      </AccordionPanel>
    </>
  );
};

export const OtherProjects = ({ otherProjects, title }) => {
  const [activeIndex, setIndex] = useState(-1);
  const { isMobile, isDesktop, themeSizes } = useResponsive();

  const setActiveIndex = (i) => {
    setIndex(i[0]);
  };
  return (
    <>
      <Box
        pad={{ bottom: "xlarge" }}
        flex="grow"
        width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}
      >
        <Heading level={3}>{`Other ${title} Projects`}</Heading>

        <Accordion onActive={setActiveIndex}>
          {otherProjects?.map((projectDetails, index) => {
            return (
              <OtherProjectItem
                key={index}
                active={activeIndex === index}
                projectDetails={projectDetails}
              />
            );
          })}
        </Accordion>
      </Box>
    </>
  );
};

const PopUpCaseStudy = ({ data }) => {
  const [isOpen, setIsOpen] = useState();

  return (
    <>
      {isOpen && (
        <Layer
          position="right"
          background={"background"}
          onEsc={() => setIsOpen(false)}
          onClickOutside={() => setIsOpen(false)}
        >
          <CaseStudyPopUp post={data} />
          <Button
            style={{ position: "absolute", top: "20px", right: "20px" }}
            icon={<Close color="brand" size="30px" />}
            onClick={() => setIsOpen(false)}
          />
        </Layer>
      )}

      <Button onClick={() => setIsOpen(true)} className="see-more-hover">
        <Box direction="row" justify="between" align="center" gap="small">
          <Paragraph>See More</Paragraph>
          <ArrowMore width="25px" height="25px" className="see-more" />
        </Box>
      </Button>
    </>
  );
};
