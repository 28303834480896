import React from 'react';

import Button from './Button'
import AnimatedTitle from "./AnimatedTitle";

const FacilitiesAboutBlock = () => {



  return (
    <div className={"custom-h-screen"}>
      <div className={"content-container h-full flex flex-col justify-between py-8 tablet:pb-16 desktop:pb-20 desktop:pt-12"}>
        {/*Header*/}
        <AnimatedTitle as={'h2'} className={"font-bold leading-[1.2] text-secondary tablet:text-xl desktop:text-3xl"}>
          Our meticulous approach to Facilities Management, combining rigorous Planned Preventative Maintenance and swift, efficient Reactive Response services, ensures compliance with regulations and industry standards.
        </AnimatedTitle>

        <div className={"flex flex-col-reverse gap-8 tablet:w-2/3 tablet:ml-auto desktop:grid desktop:grid-cols-[0.4fr_0.6fr] desktop:w-full"}>
          {/*CTA*/}
          <div className={"flex justify-center items-center tablet:justify-start desktop:items-end"}>
            <Button text={"Get In Touch"} color={"secondary"} to={"mailto:fm@bauvill.co.uk"} isExternal={true} />
          </div>

          {/*Text*/}
          <div>
            <p className={"text-sm font-medium ml-auto w-[85%] text-secondary tablet:text-lg tablet:w-full desktop:text-xl desktop:leading-[1.25]"}>
              Preplanned Maintenance (PPM) also known as scheduled maintenance, involves regular, systematic inspection, servicing, and repair of equipment and facilities to prevent unexpected failures and prolong their operational life. This proactive approach is designed to maintain equipment ensuring safety and regulatory standards, and optimising operational efficiency by addressing potential problems before they escalate into major issues. Our qualified engineers provide weekly check sheets/certifications. Any issues identified will be documented and followed by a quotation for remedial action.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacilitiesAboutBlock;