import React from "react";
import { Box } from "grommet";
import { useVideojs } from "react-videojs-hook";
import { useResponsive } from "../../hooks";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Video = ({ video }) => {
  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    controls: false,
    autoplay: true,
    responsive: true,
    bigPlayButtonCentered: true,
    fluid: true,
    muted: true,
    loop: true,
  });

  return (
    <video
      height="100%"
      ref={vjsRef}
      id={vjsId}
      className={vjsClassName}></video>
  );
};

export const VideoBlock = ({ data: { type, image, video }, data }) => {
  const { isMobile } = useResponsive();

  const featuredImage = getImage(image?.localFile);

  return (
    <Box
      className="data-vjs-player"
      height={isMobile ? "75vh" : "100vh"}
      fill="horizontal"
      flex={false}
      style={{ overflow: "hidden" }}>
      {type === "video" ? (
        <Video video={video} />
      ) : (
        <GatsbyImage
          image={featuredImage}
          alt={image?.alt || ""}
          style={{ width: "100%", height: "100%" }}
          objectFit="cover"
        />
      )}
    </Box>
  );
};