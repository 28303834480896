import React from "react";
import { Link, graphql } from "gatsby";
import Image from "gatsby-image";
import parse from "html-react-parser";
import WPGBlocks from "react-gutenberg";
import { Heading, Paragraph, Box, Grid } from "grommet";
import { useResponsive } from "../../hooks";

const BlogPostTemplate = ({ post, newsArticle = false }) => {

  console.log("post", post);
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  };

  const { isMobile } = useResponsive();

  console.log("post.blocks", post.blocks)
  return (
    <Box
      style={{ overflowY: "scroll" }}
      height="100vh"
      width={isMobile ? "100vw" : "50vw"}
      pad={{ vertical: "large", left: "medium", right: "none" }}>
      <Grid columns={isMobile ? ["flex"] : ["20%", "flex"]} gap="medium">
        {!isMobile && !newsArticle && (
          <Box margin={{ top: isMobile ? "xlarge" : "small" }}>
            <Paragraph margin="none">{post.keyInformation.scope}</Paragraph>
            <Paragraph margin="none">{post.keyInformation.cost}</Paragraph>
          </Box>
        )}
        {newsArticle && (
          <Box margin={{ top: isMobile ? "xlarge" : "small" }}>
            <Paragraph margin="none">{post.newsInfo.subtitle}</Paragraph>
            <Paragraph margin="none">{post.newsInfo.additionalInfo}</Paragraph>
          </Box>
        )}
        <Box>
          <Heading
            level="2"
            size={"medium"}
            margin={{ top: isMobile ? "medium" : "none", right: "medium" }}>
            {parse(post.title)}
          </Heading>
          <Image fluid={featuredImage.fluid} />
          {isMobile && !newsArticle && (
            <Box margin={{ top: isMobile ? "large" : "small" }}>
              <Paragraph margin="none">{post.keyInformation.scope}</Paragraph>
              <Paragraph margin="none">{post.keyInformation.cost}</Paragraph>
            </Box>
          )}
          <Box pad={{ right: "medium" }}>
            <WPGBlocks blocks={post.blocks} />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default BlogPostTemplate;
