import React from "react";
import Image from "gatsby-image";
import OverviewGallery from "../../components/OverviewGallery";

export const ReactiveOverview = ({ content }) => {
  const { title, body, responseLevels, images } = content;

  console.log("responseLevels", responseLevels);

  return (
    <OverviewGallery
      title={title}
      description={body}
      images={images}
      cards={responseLevels}
    />
    // <div style={{ padding: 20 }}>
    //   <h1>{title}</h1>
    //   <p>{body}</p>
    //   {responseLevels.map(({ level, text }, index) => {
    //     return (
    //       <div
    //         style={{
    //           padding: 5,
    //           display: "flex",
    //           flexDirection: "row",
    //           gap: 20,
    //         }}
    //       >
    //         <p>{level}</p>
    //         <p>{text}</p>
    //       </div>
    //     );
    //   })}
    //   <div
    //     style={{ padding: 20, display: "flex", flexDirection: "row", gap: 20 }}
    //   >
    //     {images.map((image, index) => {
    //       console.log("image", image);
    //       const featuredImage = {
    //         fluid: image?.localFile?.childImageSharp?.fluid,
    //         alt: image?.alt || ``,
    //       };
    //
    //       console.log("featuredImage", featuredImage);
    //       return (
    //         <Image
    //           key={index}
    //           style={{
    //             height: "100px",
    //             width: "140px",
    //           }}
    //           objectFit="cover"
    //           fluid={featuredImage.fluid}
    //         />
    //       );
    //     })}
    //   </div>
    // </div>
  );
};
