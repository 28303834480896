import React from "react";
import Image from "gatsby-image";
import CategoryScroller from "../../components/CategoryScroller";

export const MaintenanceServices = ({ content }) => {
  const { maintenanceServices } = content;

  console.log("main", maintenanceServices);

  const data = maintenanceServices?.map(({ text, image, subCategories }, index) => {
    const featuredImage = {
      fluid: image?.localFile?.childImageSharp?.fluid,
      alt: image?.alt || ``,
    };

    return {
      text,
      featuredImage,
      subCategories,
    };
  });

  console.log("finished data", data)

  return (
    <CategoryScroller title={"Pre-Planned Maintenance Services"} categories={data} />
  )
};
