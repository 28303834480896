import React, {useState, useEffect, useRef, useCallback} from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { useResponsive } from "../../hooks";
import CircleArrow from "../../assets/svgs/circle-arrow.svg";
import { navigate } from "gatsby";
import gsap from "gsap";

const Cursor = ({ children }) => {
  return (
    <div className="absolute size-[120px] -translate-x-1/2 -translate-y-1/2 bg-primary z-[999] rounded-full pointer-events-none text-white flex items-center justify-center">
      {children}
    </div>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick, onMouseEnter } = props;

  return (
    <div
      className={className}
      style={{
        cursor: "none",
        ...style,
        display: "block",
        height: "100%",
        width: "25%",
        right: 0,
        zIndex: 1000,
      }}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, onMouseEnter } = props;
  return (
    <div
      className={className}
      style={{
        cursor: "none",
        ...style,
        display: "block",
        height: "100%",
        width: "25%",
        left: 0,
        zIndex: 1000,
      }}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
    </div>
  );
}

export const CarouselBlock = ({ images, uri }) => {
  const { isMobile } = useResponsive();
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef();
  const cursorRef = useRef();
  const cursorFirstMove = useRef(false);
  const [cursorType, setCursorType] = useState("see-more");

  const onMouseEnter = (event) => {
    setIsVisible(true);
    cursorFirstMove.current = true;
  }

  const onMouseLeave = () => {
    setIsVisible(false);
    gsap.to(cursorRef.current, {
      opacity: 0,
      duration: 0.25,
      ease: "linear",
    });
  }

  const onMousemove = useCallback((event) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setCoords({ x, y });
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const cursor = cursorRef.current;

    container.addEventListener("mousemove", onMousemove, false);

    gsap.to(cursor, {
      duration: cursorFirstMove ? 0 : 0.1,
      ease: "linear",
      x: coords.x,
      y: coords.y,
      onComplete: () => {
        if (isVisible) {
          gsap.to(cursor, {
            duration: 0.25,
            opacity: 1,
            ease: "linear",
          });
        }
      }
    });

    cursorFirstMove.current = false;

    return () => {
      container.removeEventListener("mousemove", onMousemove);
    };
  }, [onMousemove, coords]);

  useEffect(() => {
    const handleScroll = () => {
      gsap.to(cursorRef.current, {
        opacity: 0,
        duration: 0.25,
        ease: "linear",
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    containerRef.current.addEventListener("mousemove", onMousemove, false);
    return () =>
      containerRef?.current?.removeEventListener("mousemove", onMousemove);
  }, [onMousemove]);

  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow onMouseEnter={() => setCursorType('next')} />,
    prevArrow: <SamplePrevArrow onMouseEnter={() => setCursorType('prev')} />,
    customPaging: function (i) {
      return <a></a>;
    },
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={`absolute z-10 opacity-0`} ref={cursorRef}>
        <Cursor background="#FF0000">
          {cursorType === "see-more" ? (
            <span className="font-medium text-center text-lg leading-[1]">
              See <br/>More
            </span>
          ) : cursorType === "prev" ? (
            <CircleArrow
              style={{height: "35%", width: "35%", transform: "rotate(180deg)"}}
            />
          ) : (
            <CircleArrow
              style={{height: "35%", width: "35%"}}
            />
          )}
        </Cursor>
      </div>

      <Slider {...settings}>
        {images?.map((item, index) => {
          const image = getImage(item?.localFile);

          return (
            <div key={index}>
              <div
                onClick={() => navigate(uri)}
                style={{
                  cursor: "none",
                  width: "100%",
                  height: "0",
                  paddingBottom: isMobile ? "125%" : "57%",
                  position: "relative",
                  overflow: "hidden",
                }}
                onMouseEnter={() => setCursorType("see-more")}
              >
                <GatsbyImage
                  image={image}
                  alt={item?.alt || ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  imgStyle={{
                    objectFit: "cover",
                    objectPosition: "50% 50%",
                  }}
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};