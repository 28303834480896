import React from "react";
import CategoryScroller from "../../components/CategoryScroller";

export const ReactiveServices = ({ content }) => {
  const { responseServices } = content;

  const data = responseServices?.map(({ text, image, subCategories }, index) => {
    const featuredImage = {
      fluid: image?.localFile?.childImageSharp?.fluid,
      alt: image?.alt || ``,
    };

    return {
      text,
      featuredImage,
      subCategories,
    };
  });

  return (
    <CategoryScroller title={"Reactive Response Services"} categories={data} />
  )
};
