import React, { useRef, useEffect } from "react";
import parser, { domToReact } from "html-react-parser";
import SymbolHealth from "../../assets/svgs/healthSymbol.svg";
import SymbolEducation from "../../assets/svgs/educationSymbol.svg";
import SymbolCommercial from "../../assets/svgs/commercialSymbol.svg";
import SymbolFacilities from "../../assets/svgs/facilitiesSymbol.svg";
import { useResponsive } from "../../hooks";
import { gsap } from "gsap";

const SectorSymbol = ({ sector }) => {
  switch (sector.toLowerCase()) {
    case "health":
      return <SymbolHealth />;
    case "education":
      return <SymbolEducation />;
    case "commercial":
      return <SymbolCommercial />;
    case "facilities":
      return <SymbolFacilities />;
  }
}

export const TitleBlock = ({ title, content, brandSpirit }) => {
  const { isMobile, isDesktop, isTablet, themeSizes, size } = useResponsive();

  const symbolRef = useRef();
  const textRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    if (symbolRef.current){
      gsap.fromTo(
        symbolRef.current,
        {
          opacity: 0,
          scale: 0.8,
          y: isMobile ? -250 : 150,
          x: isMobile ? 150 : -150,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 2,
          y: 0,
          x: 0,
        }
      );
    }

    if (titleRef.current){
      gsap.fromTo(
        titleRef.current,
        {
          opacity: 0,
          y: 150,
        },
        {
          opacity: 1,
          duration: 1,
          y: 0,
        }
      );
    }

    if (textRef.current){
      gsap.fromTo(
        textRef.current,
        {
          opacity: 0,
          y: 150,
        },
        {
          opacity: 1,
          duration: 1,
          y: 0,
        }
      );
    }
  }, [isMobile]);

  const containerStyles = {
    maxHeight: isMobile ? null : "1200px",
    maxWidth: "none",
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto 0 auto auto",
    position: "absolute",
    zIndex: 0,
  };

  return (
    <div className={"bg-light custom-h-screen relative"}>
      <div className={"content-container flex items-center h-full py-12"}>
        {/*Symbol*/}
        <div className={"absolute h-full flex items-center -left-[80%] tablet:-left-1/2 desktop:left-auto"}>
          <div ref={symbolRef} className={"*:h-[80vh]"}>
            <SectorSymbol sector={title}/>
          </div>
        </div>


        {/*Content*/}
        <div className={"relative w-full h-full ml-auto flex flex-col tablet:w-2/3"}>
          <p className={"font-medium text-sm w-2/3 max-w-full h-[40%] tablet:w-[300px]"}>
          {brandSpirit || ""}
          </p>

          <div ref={textRef} className={"h-1/2"}>
            {/*Heading*/}
            <h1 className={"text-6xl tablet:text-8xl font-bold text-secondary mb-6"}>
              {title}
            </h1>

            {/*Page content*/}
            <div>
              {parser(content, {
                replace: ({ name, children }) => {
                  if (name === "p") {
                    return (
                      <p className={"text-sm leading-[1.25] mb-4 font-medium text-secondary tablet:text-lg desktop:text-xl"}>
                        {domToReact(children)}
                      </p>
                    );
                  }
                },
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
