import React from "react";
import { Box, Heading, Grid } from "grommet";
import Image from "gatsby-image";
import { useResponsive } from "../../hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export const ClientLogos = ({ logos }) => {
  const { isMobile, isDesktop, themeSizes } = useResponsive();

  console.log("logos", logos);

  return isDesktop ? (
    <Box
      pad={{ vertical: "xlarge" }}
      flex="grow"
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}
    >
      <Heading level={3}>Clients we have worked with</Heading>

      <Grid columns={["flex", "flex", "flex", "flex"]} gap="large">
        {logos?.map((caseStudy, index) => {
          const featuredImage = {
            fluid: caseStudy?.localFile?.childImageSharp?.fluid,
            alt: caseStudy?.alt || ``,
          };

          return (
            <Box
              key={index}
              pad={{ bottom: "45%" }}
              width="100%"
              height="0"
              style={{ position: "relative" }}
            >
              <Image
                className="fit"
                objectFit="contain"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  backgroundPosition: "50% 50%",
                }}
                fluid={featuredImage.fluid}
              />
            </Box>
          );
        })}
      </Grid>
    </Box>
  ) : null;
};
