import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";

const CrossFadeImage = ({ image, alt }) => {
  const [images, setImages] = useState([]);
  const MAX_IMAGES = 5;

  useEffect(() => {
    if (image) {
      const newImage = getImage(image);
      setImages(prevImages => {
        // Remove the image if it already exists
        const filteredImages = prevImages.filter(img =>
          img.image.images.fallback.src !== newImage.images.fallback.src
        );

        // Add the new image to the end of the array
        const updatedImages = [...filteredImages, { image: newImage, key: Date.now() }];

        // Keep only the last MAX_IMAGES
        return updatedImages.slice(-MAX_IMAGES);
      });
    }
  }, [image]);

  return (
    <div className="relative h-full w-full">
      {images.map((img, index) => (
        <GatsbyImage
          key={img.key}
          image={img.image}
          alt={alt}
          className={`!absolute top-0 left-0 w-full h-full`}
          imgClassName="object-cover"
        />
      ))}
    </div>
  );
};

export default CrossFadeImage;