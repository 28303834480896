import React from "react";

const Divider = ({ inContainer = false }) => {

  return (
    <div className={`w-full my-8 ${inContainer && "content-container"}`}>
      <hr className="border-secondary w-full"/>
    </div>
  )
}
export default Divider